import React      from "react";
import { Helmet } from "react-helmet"

import Layout   from '../components/layout';
import Headline from '../components/atoms/headline/headline';
import Text     from '../components/atoms/text/text';
import Stage    from '../components/molecules/stage/stage';

export default ({ data }) => (
  <Layout siteTitle="ChancenCheckin">
    <Helmet
      htmlAttributes={{
        lang: 'de'
      }}
    >
      <meta charSet="utf-8" />
      <title>Impressum - ChancenCheckin</title>
      <meta name="description" content="Impressum ChancenCheckin –  vielfältige Möglichkeiten für Deine unternehmerische Herausforderung. Schnell. Einfach. Individuell." />
      <link rel="canonical" href="https://www.chancencheckin.de/impressum" />
    </Helmet>
    <Stage fullWidth={false}>
      <Headline>Impressum</Headline>
      <Text tag="div">
        <Headline align="left" tag="h2">Angaben gemäß § 5 TMG</Headline>
          Björn Biege, Blümchensaal 32, 21337 Lüneburg<br />
          <br />
          Sarah Schwesig, Spittastr 34, 10317 Berlin<br />
          <br />
          Lars Koch, Lenther Straße 50, 30455 Hannover<br />
          <br />
          Carlotta Schulz, Pflügerstraße 53, 12047 Berlin<br />
          <br />
          Nadine Hermann, Egertstraße 16, 75365 Calw<br />
          <br />

        <Headline align="left" tag="h2">Kontakt</Headline>
        <p>Telefon: 01627087451<br />
        E-Mail: info@chancencheckin.de</p>

        <Headline align="left" tag="h2">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</Headline>
        <p>
          Verantwortliche Personen:<br /><br />
          Björn Biege, Blümchensaal 32, 21337 Lüneburg<br />
          <br />
          Sarah Schwesig, Spittastr 34, 10317 Berlin<br />
          <br />
          Lars Koch, Lenther Straße 50, 30455 Hannover<br />
          <br />
          Carlotta Schulz, Pflügerstraße 53, 12047 Berlin<br />
          <br />
          Nadine Hermann, Egertstraße 16, 75365 Calw



        </p>

        <Headline align="left" tag="h2">EU-Streitschlichtung</Headline>
        <p>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
          <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.
          <br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <Headline align="left" tag="h2">Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</Headline>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <Headline align="left" tag="h3">Haftung für Inhalte</Headline>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
          allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
          zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
          Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
          der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
          Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>
        <Headline align="left" tag="h3">Haftung für Links</Headline>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
          haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
          der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
          Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
          Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </p>
        <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
          einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
          derartige Links umgehend entfernen.
        </p>
        <Headline align="left" tag="h3">Urheberrecht</Headline>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
          deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
          Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
          kommerziellen Gebrauch gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
          Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
          trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
          Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
        <Headline align="left" tag="h3">Bildernachweis</Headline>
        <p>
          Großes Bild oben auf der Landingpage:<br />
          Foto von <a href="https://www.pexels.com/de-de/@quark-studio-1159039?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels" target="_blank" rel="noopener noreferrer">Quark Studio</a> von <a href="https://www.pexels.com/de-de/foto/barista-cafe-drinnen-frau-2506993/?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels"  target="_blank" rel="noopener noreferrer">Pexels</a>
        </p>
        <p>
          Bilder in den Filterergebnissen der Startseite sind Bilder die von den jeweiligen Seitenbetreibern als Open Graph Daten zur Linkvorschau automatisch zur Verfügung gestellt werden.
          <br />
          Alle andere Bilder und Illustrationen stammen von uns selbst.
        </p>

      </Text>
    </Stage>
  </Layout>
)
